<template>
  <!-- eslint-disable max-len -->
<div class="mb-3 px-xl-3 text-left">
  <span class="text-muted"><strong>COUNTDOWN</strong></span><br>
  <span class="timer bg-dark text-white text-center px-2 text-xl shadow rounded-iwh">
    {{timeLeftString}}
  </span>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
// import { mapState } from 'vuex';

export default {
  name: 'CountDownTimer',
  props: ['weddingDate'],
  data: () => ({
    timeLeftString: '',
  }),
  created() {
    this.countDownToWeddingDay();
  },
  mounted() {
    this.countDownToWeddingDay();
  },
  updated() {
    this.countDownToWeddingDay();
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    countDownToWeddingDay() {
      const wdf = this.user.wedding_date.split('T')[0].split('-');
      const wdy = wdf[0]; // wedding year
      const wdm = wdf[1] - 1;// date object starts counting months from 0-11
      const wdd = wdf[2]; // wedding day of month
      const wd = new Date(wdy, wdm, wdd, 0); // e.g. const wd = new Date(2021, 8, 23, 0);
      // not const wd = new Date(this.user.wedding_date);
      const dy = new Date();
      const tl = wd - dy;
      let timer = null;
      // console.log({ wd, wda: this.user.wedding_date });
      // console.log({ wd, dy });
      if (tl > 0) {
        timer = setTimeout(() => {
          // Deprecated approach
          // const d = new Date();
          // const tl = wd - d;
          // const yr = wd.getYear() - d.getYear();
          // const mt = wd.getMonth() - d.getMonth();
          // const dt = wd.getDate() - d.getDate() - 1;
          // const h = wd.getHours() - d.getHours() + 23;
          // const m = wd.getMinutes() - d.getMinutes() + 60;
          // const s = wd.getSeconds() - d.getSeconds() + 60;
          // this.timeLeftString = `${yr}yr:${mt}mt:${dt}d:${h}h:${m}m:${s}s`;

          // Better approach
          const d = Math.floor(tl / (1000 * 60 * 60 * 24));
          const h = Math.floor((tl % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const m = Math.floor((tl % (1000 * 60 * 60)) / (1000 * 60));
          const s = Math.floor((tl % (1000 * 60)) / (1000));
          this.timeLeftString = `${d}d:${h}h:${m}m:${s}s`;
          this.countDownToWeddingDay();
        }, 1000);
      } else if (`${wd.getFullYear()}-${wd.getMonth()}-${wd.getDate()}-` === `${dy.getFullYear()}-${dy.getMonth()}-${dy.getDate()}`) {
        this.timeLeftString = 'Wedding Day!!! 💒💍👩‍❤️‍👨🥳🎉🎈';
        clearInterval(timer);
      } else if (`${wd.getMonth()}-${wd.getDate()}` === `${dy.getMonth()}-${dy.getDate()}`) {
        this.timeLeftString = `Happy ${dy.getFullYear() - wd.getFullYear()} Year Anniversary!!! 💍👩‍❤️‍👨🥳🎉🎈`;
      } else {
        this.timeLeftString = 'Have a Fruitful Union!!!';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@600&display=swap');
.timer {
  font-family: 'Big Shoulders Display', cursive !important;
}
</style>
